import type { Endpoint, Webhook } from '@square/ignition';
import type { CodegenInput } from '@square/ignition/dist/types/codegen';
import type { DataType } from '@square/ignition/dist/types/types/data-type';
import { splitCamelCaseIntoParts } from '@squareup/dex-utils-text';

const V1_PREFIX = 'v1-';

function toApiId(api: string | undefined): string {
  return `${api?.toLocaleLowerCase() || ''}-api`;
}

function toEndpointId(endpoint: string | undefined): string {
  return splitCamelCaseIntoParts(endpoint || '')
    .map((value) => value.toLowerCase())
    .join('-');
}

/**
 * This will take a webhook id, and convert to a proper id that
 * can be used with ignition's Graph object.
 * Unfortunately, ignition doesn't handle the `v1-` in webhooks
 * properly. Putting this in until we fix ignition
 * @param webhook The potential webhook id to convert
 * @returns A valid webhook id for ignition's Graph
 */
function toWebhookId(webhook: string | undefined): string {
  if (!webhook) {
    return '';
  }

  const webhookId = webhook.startsWith(V1_PREFIX)
    ? webhook.replace(V1_PREFIX, '')
    : webhook;

  return webhookId.toLowerCase();
}

/**
 * Converts an Endpoint object to a valid parameter
 * to be used in the url of the Square Technical Reference
 * Specifically, if it is a v1 endpoint, it prepends V1 to the start
 * @param endpoint The endpoint to convert to a path param
 * @returns A valid path param that represents all endpoints
 */
function toEndpointPathParam(endpoint: Endpoint): string {
  if (endpoint.isV1 && !endpoint.name.startsWith(V1_PREFIX)) {
    return V1_PREFIX + endpoint.name;
  }

  return endpoint.name;
}

/**
 * Converts a Webhook object to a valid parameter
 * to be used in the url of the Square Technical Reference
 * Specifically, if it is a v1 webhook, it prepends V1 to the start
 * @param webhook The webhook to convert to a path param
 * @returns A valid path param that represents all webhooks
 */
function toWebhookPathParam(webhook: Webhook): string {
  if (webhook.isV1 && !webhook.name.startsWith(V1_PREFIX)) {
    return V1_PREFIX + webhook.name;
  }

  return webhook.name;
}

/**
 * DataType enum isn't exposed by ignition. To make Typescript happy,
 * we'll use this mapper to turn a string into a DataType
 * @param dataType The string to convert
 * @returns The string as an ignition dataType
 */
function toIgnitionDataType(
  dataType:
    | 'string'
    | 'number'
    | 'integer'
    | 'boolean'
    | 'object'
    | 'array'
    | 'map'
): DataType {
  switch (dataType) {
    case 'integer':
      return dataType as DataType.integer;
    case 'number':
      return dataType as DataType.number;
    case 'boolean':
      return dataType as DataType.boolean;
    case 'object':
      return dataType as DataType.object;
    case 'array':
      return dataType as DataType.array;
    case 'map':
      return dataType as DataType.map;
    case 'string':
    default:
      return dataType as DataType.string;
  }
}

/**
 * Converts an ignition CodegenInput object to a valid input object
 * for the Fern API
 * @param ignitionInput The ignition input object
 * @param endpoint The endpoint object
 * @param accessToken The access token to to be used in the request
 */
function toFernInput(
  ignitionInput: CodegenInput,
  endpoint: Endpoint,
  accessToken?: string,
  environment?: string
): unknown {
  let requestBody: object = {};
  if (endpoint.supportsFileUpload) {
    requestBody = {
      request: ignitionInput.bodyParameters,
      image_file: ignitionInput.fileParameters?.filename
        ? ignitionInput.fileParameters?.filename
        : null,
    };
  } else {
    requestBody = ignitionInput.bodyParameters as object;
  }

  return {
    auth: {
      type: 'bearer',
      token: accessToken || 'YOUR_ACCESS_TOKEN',
    },
    pathParameters: ignitionInput.pathParameters,
    queryParameters: ignitionInput.queryParameters,
    requestBody,
    environment,
  };
}

export {
  toApiId,
  toEndpointId,
  toWebhookId,
  toWebhookPathParam,
  toEndpointPathParam,
  toIgnitionDataType,
  toFernInput,
};
